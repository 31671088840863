import styled from 'styled-components';

export const TimeView = styled.div`
  height: 98px;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TimeBlock = styled.button`
  width: 50%;
  height: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const TimeTitle = styled.span`
  text-align: center;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.secondary};
`;

export const TimeText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 20px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const DateText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 12px;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
`;

export const Input = styled.select`
  background-color: #fff;
  border-radius: 8px;
  border: ${({ theme: { colors } }): string => `1px solid ${colors.secondary}`};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 18px;
  height: 48px;
  outline: none;
  width: 100%;
`;

export const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  color: ${({ theme: { colors } }): string => colors.primary};
  font-size: 9px;
`;
