import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";

import { get } from "../../utils/http";

import {
  setPickupRange,
  setDropOffRange,
} from "../../store/shopping-cart/actions";

import { AppState } from "../../store";
import { Range } from "../../types/range";
import { Option } from "../../types/option";
import { Order, Time } from "../../types/order";
// TODO utiliser Address et pas ExtendedGoogleMapsPlace
// import { Address } from '../../types/address';
import { ExtendedGoogleMapsPlace } from "../../store/address/types";
import userLanguage from "../hooks/useLanguage";

interface TimeSlot {
  startTime: string;
  endTime: string;
  dropoffLeadTimeInMins?: number;
}

interface AvailabilitiesResult {
  pickupTimeslots: TimeSlot[];
  dropoffTimeslots: TimeSlot[];
}

export interface Day {
  isDefault: boolean;
  date: Dayjs;
  day: string;
  printableDate: string;
  ranges: Range[];
}

enum ModalType {
  NONE = "NONE",
  PICKUP = "PICKUP",
  DROP_OFF = "DROP_OFF",
}

interface Days {
  [key: string]: Day;
}

export interface UseDateRangePickerResult {
  days: Day[];
  ranges: Range[];
  onDayChange: (index: number) => void;
  onRangeChange: (index: number) => void;
  onPickupPress: () => void;
  onDropOffPress: () => void;
  onModalClose: () => void;
  onValidate: () => void;
  isModalShown: boolean;
  loading: boolean;
  loaded: boolean;
  pickupRange?: Range;
  dropOffRange?: Range;
  dayIndex: number;
  rangeIndex: number;
}

const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

export const usePickupDropOffRangePicker = (
  reschedule?: boolean,
  order?: Order
): UseDateRangePickerResult => {
  const dispatch = useDispatch();
  const locale = userLanguage();
  const timeSlotsToDays = useCallback(
    (timeSlots: TimeSlot[], defaultTime?: Time): Day[] => {
      const daysObject: Days = {};
      const defaultTimeKey = defaultTime
        ? dayjs(defaultTime.startTime).format("DD-MM-YY")
        : "";
      const defaultStartTime = defaultTime
        ? dayjs(defaultTime.startTime).format("HH:mm")
        : "";
      const defaultEndTime = defaultTime
        ? dayjs(defaultTime.endTime).format("HH:mm")
        : "";

      timeSlots.forEach((timeSlot: TimeSlot) => {
        const startDate = dayjs(timeSlot.startTime);
        const endDate = dayjs(timeSlot.endTime);
        const key = startDate.format("DD-MM-YY");
        const startTime = startDate.format("HH:mm");
        const endTime = endDate.format("HH:mm");
        const range: Range = {
          isDefault:
            startTime === defaultStartTime && endTime === defaultEndTime,
          startDate,
          endDate,
          startTime,
          endTime,
          dropOffDelay: timeSlot.dropoffLeadTimeInMins,
        };

        if (daysObject[key]) {
          daysObject[key] = {
            ...daysObject[key],
            ranges: [...daysObject[key].ranges, range],
          };
        } else {
          daysObject[key] = {
            isDefault: key === defaultTimeKey,
            date: startDate
              .subtract(startDate.hour(), "hour")
              .subtract(startDate.minute(), "minute"),
            day: startDate.locale(locale).format("dddd"),
            printableDate: startDate.locale(locale).format("dddd DD MMMM YYYY"),
            ranges: [range],
          };
        }
      });

      const days: Day[] = Object.keys(daysObject).map(dayKey => {
        const ranges = daysObject[dayKey].ranges;

        daysObject[dayKey].ranges = [
          ...ranges.sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : 1;
          }),
        ];

        return daysObject[dayKey];
      });

      return days.sort((a, b) => {
        return a.date.isBefore(b.date) ? -1 : 1;
      });
    },
    [locale]
  );

  const state = useSelector<AppState, AppState>(state => state);
  const pickupRange = useSelector<AppState, Range | undefined>(
    state => state.shoppingCart.pickupRange
  );
  const dropOffRange = useSelector<AppState, Range | undefined>(
    state => state.shoppingCart.dropOffRange
  );
  const currentOption = useSelector<AppState, Option | undefined>(
    state => state.shoppingCart.currentOption
  );
  // TODO utiliser Address et pas ExtendedGoogleMapsPlace
  const address = useSelector<AppState, ExtendedGoogleMapsPlace | undefined>(
    state => state.shoppingCart.address
  );
  const [pickupDays, setPickupDays] = useState<Day[]>([]);
  const [dropOffDays, setDropOffDays] = useState<Day[]>([]);
  const [dayIndex, setDayIndex] = useState<number>(0);
  const [rangeIndex, setRangeIndex] = useState<number>(0);
  const [modalType, setModalType] = useState<ModalType>(ModalType.NONE);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const dispatchDropOffRange = (
    pickupRange: Range | undefined,
    dropOffDays: Day[],
    dropOffRange?: Range,
    alwaysUpdate = false
  ): Day[] => {
    if (pickupRange && pickupRange.dropOffDelay) {
      const minDate = dayjs(pickupRange.endDate).add(
        pickupRange.dropOffDelay,
        "minute"
      );
      const filteredDropOffDays = dropOffDays
        .map(dropOffDay => {
          if (
            dropOffDay.ranges[dropOffDay.ranges.length - 1].startDate.isBefore(
              minDate
            )
          ) {
            return null;
          } else {
            let newRanges = [...dropOffDay.ranges];
            newRanges = newRanges.filter(
              range =>
                range.startDate.isAfter(minDate) ||
                range.startDate.isSame(minDate)
            );
            return {
              ...dropOffDay,
              ranges: newRanges,
            };
          }
        })
        .filter(notEmpty);
      if (
        filteredDropOffDays.length > 0 &&
        filteredDropOffDays[0].ranges.length > 0
      ) {
        const nightRanges = filteredDropOffDays[0].ranges.filter(
          range =>
            range.startDate.isAfter(
              dayjs(range.startDate)
                .set("hour", 18)
                .set("minute", 0)
            ) ||
            range.startDate.isSame(
              dayjs(range.startDate)
                .set("hour", 18)
                .set("minute", 0)
            )
        );
        if (nightRanges.length > 0) {
          const nightRange = nightRanges[0];
          if (
            !dropOffRange ||
            dayjs(dropOffRange.startDate).isBefore(nightRange.startDate)
          ) {
            dispatch(setDropOffRange(nightRange));
          }
        } else if (
          !dropOffRange ||
          dropOffRange.startDate.isBefore(
            filteredDropOffDays[0].ranges[0].startDate
          ) ||
          alwaysUpdate
        ) {
          dispatch(setDropOffRange(filteredDropOffDays[0].ranges[0]));
        }
      }
      return filteredDropOffDays;
    }
    return [];
  };

  const filteredDropOffDays = useMemo<Day[]>(() => {
    return dispatchDropOffRange(pickupRange, dropOffDays, dropOffRange);
  }, [pickupRange, dropOffDays, dropOffRange]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if ((reschedule && order) || (currentOption && address)) {
        setLoading(true);

        try {
          const result = await get<AvailabilitiesResult>(
            reschedule && order
              ? `/orders/${order.id}/reschedule/availabilities`
              : `/availabilities?lat=${address &&
                  address.coordinates.lat}&lng=${address &&
                  address.coordinates.lng}&serviceClass=${currentOption &&
                  currentOption.id}`,
            state
          );

          if (result.ok && result.parsedBody) {
            // console.log("... result.parsedBody", result.parsedBody);
            const pickupDays = timeSlotsToDays(
              result.parsedBody.pickupTimeslots,
              order?.pickupTime
            );

            setPickupDays(pickupDays);
            const dropOffDays = timeSlotsToDays(
              result.parsedBody.dropoffTimeslots,
              order?.dropoffTime
            );
            setDropOffDays(dropOffDays);

            // Init default pickup range
            if (!pickupRange || reschedule) {
              const defaultPickupDay =
                pickupDays.find(it => it.isDefault) || pickupDays[0];
              const defaultRange =
                defaultPickupDay?.ranges.find(it => it.isDefault) ||
                defaultPickupDay?.ranges[0];
              dispatch(setPickupRange(defaultRange));
            }

            // Init default dropOff range
            if (!dropOffRange || reschedule) {
              const defaultDropOffDay =
                dropOffDays.find(it => it.isDefault) || dropOffDays[0];
              const defaultRangeD =
                defaultDropOffDay?.ranges.find(it => it.isDefault) ||
                defaultDropOffDay?.ranges[0];
              dispatch(setDropOffRange(defaultRangeD));
            }

            setLoaded(true);
          }
        } catch (error) {
          console.error(error);
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [currentOption, address, dispatch]);

  const onDayChange = useCallback((index: number) => {
    setDayIndex(index);
    setRangeIndex(0);
  }, []);

  const onRangeChange = useCallback((index: number) => {
    setRangeIndex(index);
  }, []);

  const onPickupPress = useCallback(() => {
    setModalType(ModalType.PICKUP);
  }, []);

  const onDropOffPress = useCallback(() => {
    setModalType(ModalType.DROP_OFF);
  }, []);

  const onModalClose = useCallback(() => {
    setDayIndex(0);
    setRangeIndex(0);
    setModalType(ModalType.NONE);
  }, []);

  const onValidate = useCallback(async () => {
    if (modalType === ModalType.PICKUP) {
      dispatch(setPickupRange(pickupDays[dayIndex].ranges[rangeIndex]));
      // TODO : voir pourquoi ici ça ne met pas à jour le dropOffRange ??
      dispatch(setDropOffRange(filteredDropOffDays[0].ranges[0]));
    } else if (modalType === ModalType.DROP_OFF) {
      dispatch(
        setDropOffRange(filteredDropOffDays[dayIndex].ranges[rangeIndex])
      );
    }
    onModalClose();
  }, [
    modalType,
    dispatch,
    pickupDays,
    filteredDropOffDays,
    dayIndex,
    rangeIndex,
    onModalClose,
  ]);

  const ranges = useMemo<Range[]>(() => {
    let ranges: Range[] = [];
    if (modalType === ModalType.PICKUP) {
      ranges = pickupDays[dayIndex] ? pickupDays[dayIndex].ranges : [];
    } else if (modalType === ModalType.DROP_OFF) {
      ranges = filteredDropOffDays[dayIndex]
        ? filteredDropOffDays[dayIndex].ranges
        : [];
    }

    if (rangeIndex >= ranges.length && rangeIndex !== 0) {
      setRangeIndex(ranges.length > 0 ? ranges.length - 1 : 0);
    }

    return ranges;
  }, [modalType, pickupDays, filteredDropOffDays, dayIndex, rangeIndex]);

  return {
    days: modalType === ModalType.DROP_OFF ? filteredDropOffDays : pickupDays,
    ranges,
    onDayChange,
    onRangeChange,
    onPickupPress,
    onDropOffPress,
    onModalClose,
    onValidate,
    isModalShown: modalType !== ModalType.NONE,
    loading,
    loaded,
    pickupRange,
    dropOffRange,
    dayIndex,
    rangeIndex,
  };
};

export default usePickupDropOffRangePicker;
