import React, { FC, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select'

import {
  ModalContentContainer,
  SelectorsContainer,
  ValidateButton,
  ValidateButtonContainer,
  InputSecondary,
  SelectWrapper,
  SelectContainer,
  ModalTitle,
  ValidateText,
} from './style/modal';
import { Modal } from '../modal';

import { Day } from './use-pickup-drop-off-range-picker';
import { Range } from '../../types/range';

interface Props {
  isModalShown: boolean;
  days: Day[];
  ranges: Range[];
  onModalClose: () => void;
  onValidate: () => void;
  onDayChange: (index: number) => void;
  onRangeChange: (index: number) => void;
  dayIndex: number;
  rangeIndex: number;
}

interface Value {
  value: number;
  label: string;
}

const PickupDropOffRangePickerModal: FC<Props> = ({
  isModalShown,
  days,
  ranges,
  onModalClose,
  onValidate,
  onDayChange,
  onRangeChange,
  dayIndex,
  rangeIndex,
}) => {
  const daysInSelect: Value[] = days.map((day, index) => (
    {
      value: index,
      label: day.printableDate,
    }
  ));

  const rangesInSelect: Value[] =  ranges.map((range, index) => (
    {
      value: index,
      label: `${range.startTime} - ${range.endTime}`,
    }
  ));

  const styles = {
    menuList: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      height: '200px',
      display: 'block',
    }),
    control: (styles: CSSProperties): CSSProperties => ({
      ...styles,
      display: 'none',
    }),
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    option: (styles: CSSProperties, state: any): CSSProperties => ({
      ...styles,
      'fontFamily': 'Lato, sans-serif',
      'backgroundColor': state.isSelected ? '#31aded' : 'white',
    }),
  };

  return (
    <Modal open={isModalShown} closeModal={onModalClose}>
      <ModalContentContainer>
        <ModalTitle>
          <InputSecondary><FormattedMessage id="cart.shippingTime.modal.title" /></InputSecondary>
        </ModalTitle>
        <SelectorsContainer>
          <SelectWrapper>
            <SelectContainer>
              <Select<Value>
                value={days[dayIndex] && { value: dayIndex, label: days[dayIndex].printableDate }}
                onChange={(values): void => {values && !Array.isArray(values) && onDayChange(values.value)}}
                options={daysInSelect}
                styles={styles}
                menuIsOpen
              />
            </SelectContainer>
          </SelectWrapper>
          <SelectWrapper>
            <SelectContainer>
              <Select
                value={ranges[rangeIndex] && { value: rangeIndex, label: `${ranges[rangeIndex].startTime} - ${ranges[rangeIndex].endTime}` }}
                onChange={(values): void => {values && !Array.isArray(values) && onRangeChange(values.value)}}
                options={rangesInSelect}
                styles={styles}
                menuIsOpen
              />
            </SelectContainer>
          </SelectWrapper>
        </SelectorsContainer>
        <ValidateButtonContainer>
          <ValidateButton onClick={onValidate}>
            <ValidateText><FormattedMessage id="cart.shippingTime.validate" /></ValidateText>
          </ValidateButton>
        </ValidateButtonContainer>
      </ModalContentContainer>
    </Modal>
  );
};

export default PickupDropOffRangePickerModal;
