import styled from 'styled-components';

export const ModalTitle = styled.div`
  padding-top: 30px;
  text-align: center;
  margin-bottom: 30px;
`;

export const ModalContentContainer = styled.div`
  height: 450px;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  flex-direction: column;
`;

export const ValidateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 4px;
  height: 100px;
`;

export const ValidateButton = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  height: 48px;
  border-radius: 25px;
`;

export const SelectorsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const InputSecondary = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 20px;
  color: ${({ theme: { colors } }): string => colors.secondary};
`;

export const ValidateText = styled(InputSecondary)`
  color: ${({ theme: { colors } }): string => colors.white};
`;

export const SelectWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    &:first-child {
      padding-left: 9px;
    }

    &:last-child {
      padding: 0 9px;
    }
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding: 0 30px;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
`;
